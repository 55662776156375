import ApiService from '@/assets/js/api.service';
import {isSuccess} from '@/assets/js/common.utils';
import {hideLoading, showLoading} from '@/assets/js/common.alert';

export const goAws = service => {
    if (service) {
        showLoading();
        ApiService.post(`/v1/aws/sts/${service.toLowerCase()}`)
            .then(res => {
                if (isSuccess(res) && res.federation) {
                    window.open(res.federation);
                }
                hideLoading();
            })
            .catch(() => {
                hideLoading();
            });
    }
};

// export const downloadS3Object = (validate, url, uploadKey, callback) => {
//     if (validate) {
//         ApiService.post(url, {uploadKey})
//             .then(res => {
//                 if (res) window.open(res);
//                 if (callback instanceof Function) callback();
//             })
//             .catch(e => {
//                 console.error(e);
//                 if (callback instanceof Function) callback();
//             });
//     } else {
//         if (callback instanceof Function) callback();
//     }
// };
