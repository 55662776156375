<template>
    <main class="swcluster-main" id="swcluster-datasandbox">
        <!-- page-header -->
        <div class="page-header header-divider">
            <div class="header-component">
                <CommonBreadcrumb :show-title="true" />
            </div>
        </div>
        <!-- page-body -->
        <div class="page-body page-component">
            <!-- page-aside -->
            <SandboxSideBar />
            <!-- page-contents -->
            <div class="page-contents">
                <!-- board-list-container -->
                <div class="board-list-container">
                    <!-- board-list -->
                    <ul class="board-list">
                        <!-- board-item -->
                        <li class="board-item" v-for="(item, index) in renderItems" :key="index">
                            <div class="board">
                                <div class="board-column column-idx">{{ getPageNo(paging, index) }}</div>
                                <div class="board-column column-content">
                                    <a href="javascript:" class="board-link">
                                        <p class="board-title swcc-text-skip" :title="item.name" style="width: 600px">{{ item.name }}</p>
                                        <div class="board-meta">
                                            <span class="text">{{ timestampToDateFormat(item.updateDt, 'yyyy.MM.dd') }}</span>
                                            <span class="text">{{ convertToStorageBytes(item.size) }}</span>

                                            <div class="badge">{{ item.category }}</div>
                                            <!--                                            <template v-if="item.hasFile">-->
                                            <!--                                                <span class="text">{{ item.fileSize }}</span>-->
                                            <!--                                                <div class="badge">{{ item.fileType }}</div>-->
                                            <!--                                            </template>-->
                                        </div>
                                    </a>
                                </div>
                                <div class="board-column column-actions">
                                    <button class="btn-text" @click="downloadMart(item.key)"><span class="text">다운로드</span></button>
                                    <button class="btn-text" @click="deleteDataMart(item.key)"><span class="text text-red">삭제</span></button>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!-- pagination -->
                    <CommonPagination :paging="paging" :pageFunc="pageFunc" />
                </div>
                <!-- board-list-container -->
            </div>
            <!-- //page-contents -->
        </div>
        <!-- //page-body -->
        <!-- page-bottom -->
        <div v-if="session.name !== null && showButton && !uploadToggle" class="page-bottom bottom-fixed" style="bottom: 48px">
            <div class="bottom-board-upload bottom-component">
                <button class="btn btn-primary" @click="uploadToggle = true"><span class="text">업로드</span></button>
            </div>
        </div>
        <!-- //page-bottom -->
        <div class="page-bottom bottom-fixed">
            <div>
                <div class="bottom-search-container bottom-component">
                    <div class="bottom-search">
                        <div class="search-column column-form">
                            <input v-model.trim="keyword" type="search" :placeholder="`데이터 마트를 검색하세요.`" @keyup.enter="searchKeyword" />
                        </div>
                        <div class="search-column column-button">
                            <button class="btn-icon" @click="searchKeyword"><i class="icon-search"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <CommonMultiFileUpload
            v-if="uploadToggle"
            v-model:toggle="uploadToggle"
            source="/v1/aws/s3/marts/uploads"
            path="origin"
            :validate-func="validateFile"
            :parent-folder-func="getParentFolderFunc"
            validate-text="업로할 파일에 한글이 포함되어있습니다. 영문 또는 숫자로만 이루어져 있는 이름의 파일을 업로드해주세요."
            @completed="initDataMartList" />
    </main>
</template>

<script>
import {computed, onMounted, onUnmounted, reactive, ref, watch} from 'vue';
import CommonPagination from '@/components/common/CommonPagination';
import {useRoute, useRouter} from 'vue-router';
import CommonBreadcrumb from '@/components/common/layout/CommonBreadcrumb';
import {getValueByQuery} from '@/assets/js/route.utils';
import ApiService from '@/assets/js/api.service';
import {convertToStorageBytes, getExt, getItems, getPageNo, isSuccess, lengthCheck, paginate, timestampToDateFormat} from '@/assets/js/common.utils';
import SandboxSideBar from '@/components/sandbox/SandboxSideBar';
import {goAws} from '@/assets/js/modules/aws/module';
import {useStore} from 'vuex';
import CommonMultiFileUpload from '@/components/common/CommonMultiFileUpload';
import {checkKor} from '@/assets/js/common.validate';

export default {
    name: 'DataMart',
    components: {CommonMultiFileUpload, SandboxSideBar, CommonBreadcrumb, CommonPagination},
    setup: function () {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();

        const session = computed(() => store.state.auth.session);

        const items = ref([]);
        const paging = reactive({
            pageNo: getValueByQuery(route.query, 'pageNo', true, 1),
            pageSize: 10,
            totalCount: computed(() => filteredItems.value.length),
        });
        const uploadToggle = ref(false);

        const keyword = ref('');
        const search = ref(getValueByQuery(route.query, 'search', false, ''));

        const pageFunc = pageNo => {
            router.push({query: {pageNo: pageNo}});
        };

        const categories = [
            {prefix: 'origin', name: '원본'},
            {prefix: 'source', name: '가공'},
        ];

        const checkAllFilter = item => {
            let result = false;
            for (let i = 0; i < categories.length; i++) {
                if (item.key.indexOf(`${categories[i].prefix}/`) === 0) {
                    result = true;
                    break;
                }
            }
            return result;
        };

        const getCategoryByKey = item => {
            for (let i = 0; i < categories.length; i++) {
                const filter = `${categories[i].prefix}/`;
                if (item.key.indexOf(filter) === 0) {
                    return categories[i].name;
                }
            }
            return '';
        };

        const currentQuery = computed(() => route.query);
        const searchKeyword = () => {
            router.push({query: {...currentQuery.value, pageNo: 1, search: keyword.value}});
        };

        const filterKeyword = arr => {
            if (search.value) {
                return arr.filter(x => x.key && x.key.indexOf(search.value) > -1);
            }
            return arr;
        };

        const filteredItems = computed(() =>
            filterKeyword(items.value.filter(x => x.key.slice(-1) !== '/' && checkAllFilter(x))).map(x => ({
                ...x,
                category: getCategoryByKey(x),
                ext: getExt(x.key),
            })),
        );

        const renderItems = computed(() => paginate(filteredItems.value, paging.pageNo, paging.pageSize));

        const getDataMartList = () => {
            ApiService.query('/v1/aws/s3/marts', {}).then(res => {
                if (lengthCheck(res)) {
                    const targets = getItems(res).sort(function (a, b) {
                        if (a.updateDt > b.updateDt) return -1;
                        if (a.updateDt === b.updateDt) return 0;
                        if (a.updateDt < b.updateDt) return 1;
                    });
                    items.value = targets.map(x => ({...x, name: x.key.substring(x.key.lastIndexOf('/') + 1)}));
                }
            });
        };

        const initDataMartList = () => {
            getDataMartList();
            router.push({});
        };

        const showButton = ref(true);
        const toggleButtonHandler = () => {
            const scrollTop = window.scrollY;
            const innerHeight = window.innerHeight;
            const scrollHeight = document.body.scrollHeight;
            const isShow = !(scrollTop + innerHeight >= scrollHeight);
            if (showButton.value !== isShow) showButton.value = isShow;
        };

        const isLoading = ref(false);
        const downloadMart = uploadKey => {
            if (session.value.name === null) return;
            isLoading.value = true;

            ApiService.post('/v1/aws/s3/marts/downloads', {uploadKey})
                .then(res => {
                    if (res) {
                        window.open(res);
                    }
                    isLoading.value = false;
                })
                .catch(e => {
                    console.error(e);
                    isLoading.value = false;
                });
        };

        const deleteDataMart = uploadKey => {
            if (session.value.name === null) return;

            if (confirm('데이터 마트를 삭제하시겠습니까? 삭제된 데이터는 복구할 수 없습니다.')) {
                ApiService.put('/v1/aws/s3/marts', {uploadKey}).then(res => {
                    if (isSuccess(res)) {
                        initDataMartList();
                    } else {
                        alert('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
                    }
                });
            }
        };

        const validateFile = file => {
            if (file) {
                return checkKor(file.name);
            }
            return true;
        };

        const getParentFolderFunc = file => {
            if (file && file.name && file.name.indexOf('.') > -1) {
                return file.name.substring(0, file.name.lastIndexOf('.'));
            }
            return '';
        };

        watch(
            () => route.query,
            () => {
                if (route.name !== 'DataMart') {
                    return;
                }
                paging.pageNo = getValueByQuery(route.query, 'pageNo', true, 1);
                search.value = getValueByQuery(route.query, 'search', false, '');
            },
        );

        onMounted(() => {
            document.addEventListener('scroll', toggleButtonHandler);
        });

        onUnmounted(() => {
            document.removeEventListener('scroll', toggleButtonHandler);
        });

        if (session.value.manager) {
            router.push({name: 'Main'});
        } else {
            getDataMartList();
        }

        return {
            session,
            keyword,
            uploadToggle,
            renderItems,
            paging,
            showButton,
            searchKeyword,
            getPageNo,
            downloadMart,
            deleteDataMart,
            initDataMartList,
            validateFile,
            getParentFolderFunc,
            pageFunc,
            goAws,
            timestampToDateFormat,
            convertToStorageBytes,
        };
    },
};
</script>
