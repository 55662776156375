<template>
    <div class="layer-container">
        <div class="popup" id="popup-upload">
            <div class="upload-container">
                <div v-if="useSelect" class="form-fields" style="margin: 24px 24px 8px">
                    <h2 class="title">{{ selectTitle }}</h2>
                    <div class="form-row" style="margin-top: 8px">
                        <div class="form-select-box">
                            <select v-model="selected" class="form-select">
                                <option v-for="(option, index) in selectOptions" :value="option.value" :key="index">{{ option.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div
                    class="upload-droppable"
                    @click="clickFile"
                    @drop.prevent="dropFile"
                    @dragover.prevent="addClass"
                    @dragenter.prevent
                    @dragleave.prevent="removeClass"
                    :class="{'is-active': isActive, 'upload-droppable-min': files.length > 0}"
                    style="cursor: pointer">
                    <input v-show="false" ref="fileUpload" type="file" @change="selectFiles" multiple />
                    <div class="upload-droppable-contents">
                        <i class="icon-upload"></i>
                        <h3 class="droppable-title">샘플 업로드</h3>
                        <p class="droppable-text">여기에 파일을 드래그하세요.</p>
                    </div>
                </div>

                <div v-if="files.length > 0" class="upload-list">
                    <!-- upload-item -->
                    <article v-for="(file, index) in files" class="upload-item" :key="index">
                        <header class="item-header">
                            <h4 class="title">{{ file.name }}</h4>
                            <span v-if="file.fail" class="progress-percent stb-progress-fail">업로드 실패</span>
                            <span v-else class="progress-percent">{{ file.progress }}%</span>
                            <button class="btn-delete" @click="removeFile(index)"><i class="icon-delete"></i></button>
                        </header>
                        <div class="progress">
                            <div class="progress-bar">
                                <div v-if="file.fail" class="progress-value stb-progress-fail" style="width: 100%"></div>
                                <div v-else class="progress-value" :style="{width: `${file.progress}%`}"></div>
                            </div>
                        </div>
                    </article>
                </div>

                <div class="upload-buttons">
                    <button class="btn btn-cancel" @click="closeModal"><span class="text">닫기</span></button>
                    <button class="btn btn-ok" @click="clickUpload"><span class="text">업로드</span></button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {ref, toRef} from 'vue';
import {splitUpload} from '@/assets/js/split.utils.min';
import {guid} from '@/assets/js/common.utils';

export default {
    name: 'CommonMultiFileUpload',
    props: {
        toggle: Boolean,
        source: {
            type: String,
            required: true,
        },
        path: {
            type: String,
            required: false,
            default: '',
        },
        limitCount: {
            type: Number,
            required: false,
            default: 1,
        },
        validateFunc: Function,
        validateText: String,
        useUniqueKey: Boolean,
        useSelect: Boolean,
        selectTitle: String,
        selectOptions: Array,
        currentSelected: String,
        parentFolderFunc: Function,
    },
    emits: ['update:toggle', 'completed'],
    setup(props, {emit}) {
        const isLoading = ref(false);

        const files = ref([]);
        const fileUpload = ref(null);
        const isActive = ref(false);

        const selected = ref('');
        if (props.currentSelected) {
            selected.value = toRef(props, 'currentSelected').value;
        } else if (props.selectOptions && props.selectOptions.length > 0) {
            selected.value = toRef(props, 'selectOptions').value[0].value;
        }

        const addClass = () => {
            isActive.value = true;
        };
        const removeClass = () => {
            isActive.value = false;
        };

        const pushFile = targetFile => {
            files.value.push({
                name: targetFile.name,
                size: targetFile.size,
                progress: 0,
                file: targetFile,
                completed: false,
                fail: false,
            });
        };

        const checkFile = fileList => {
            let invalidated = false;
            let msg = '';
            files.value = files.value.filter(x => !x.completed);

            if (files.value.length >= props.limitCount) {
                msg = `파일은 최대 ${props.limitCount}개만 업로드 가능합니다.`;
            } else if (props.validateFunc instanceof Function && props.validateFunc(fileList[0])) {
                msg = props.validateText;
            } else {
                invalidated = true;
            }

            if (invalidated) {
                for (let i = 0; i < fileList.length; i++) {
                    pushFile(fileList[i]);
                }
            }

            fileUpload.value.value = null;

            if (!invalidated) {
                alert(msg);
            }
        };

        const selectFiles = () => {
            // if (fileUpload.value.files && fileUpload.value.files.length > 0) {
            checkFile(fileUpload.value.files);
            // }
        };

        const dropFile = e => {
            isActive.value = false;
            checkFile(e.dataTransfer.files);
        };

        const clickFile = () => {
            if (fileUpload.value) {
                fileUpload.value.click();
            }
        };

        const removeFile = index => {
            files.value.splice(index, 1);
        };

        const closeModal = () => {
            if (isLoading.value) {
                if (confirm('파일 업로드 중입니다. 업로드를 중지 하겠습니까?')) {
                    emit('update:toggle', false);
                }
            } else {
                emit('update:toggle', false);
            }
        };

        const multiSplitUpload = async () => {
            isLoading.value = true;

            let path = props.path;
            if (props.path && props.path.indexOf('/') === 0) path = path.substring(1, path.length);
            // if (props.useSelect && selected.value) {
            //     path = path + '/' + selected.value;
            // }
            // 실패된 파일 다시 초기화
            files.value.forEach(x => {
                x.fail = false;
            });
            const successFiles = [];
            for (let i = 0; i < files.value.length; i++) {
                let uniqueKey = props.useSelect && selected.value ? selected.value : '';
                if (props.useUniqueKey) {
                    uniqueKey = uniqueKey ? uniqueKey + '/' + guid(8) : guid(8);
                } else if (props.parentFolderFunc instanceof Function) {
                    uniqueKey = uniqueKey ? uniqueKey + '/' + props.parentFolderFunc(files.value[i]) : props.parentFolderFunc(files.value[i]);
                }

                const res = await splitUpload(
                    props.source,
                    {headers: []},
                    files.value[i].file,
                    {
                        uniqueKey,
                        initPath: path,
                    },
                    percent => {
                        files.value[i].progress = percent.toFixed(0);
                    },
                );
                if (res.code === 200) {
                    successFiles.push({...res.data, size: files.value[i].size});
                    files.value[i].file = null;
                    files.value[i].completed = true;
                } else {
                    files.value[i].fail = true;
                }
            }
            isLoading.value = false;
            if (successFiles.length > 0) {
                emit('completed', successFiles);
                alert('업로드 완료되었습니다.');
            } else {
                alert('업로드 실패했습니다.');
            }
        };

        const clickUpload = () => {
            if (isLoading.value) return;

            if (files.value.filter(x => !x.completed).length > 0) {
                if (confirm('파일을 업로드 하시겠습니까?')) {
                    multiSplitUpload().then(() => {});
                }

                // showConfirm({
                //     title: '업로드 하시겠습니까?',
                //     text: '파일을 업로드 하시겠습니까?',
                //     leftFunc: multiSplitUpload,
                //     rightFunc: () => {
                //         isLoading.value = false;
                //     },
                // });
            } else {
                alert('업로드할 파일을 선택해주세요.');
            }
        };

        return {
            files,
            fileUpload,
            isActive,
            selected,
            addClass,
            removeClass,
            selectFiles,
            dropFile,
            clickFile,
            removeFile,
            closeModal,
            clickUpload,
        };
    },
};
</script>
