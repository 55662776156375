<template>
    <aside class="page-aside">
        <nav class="snb">
            <ol class="snb-list">
                <li v-for="(item, index) in items" class="snb-item" :class="{'is-active': currentRouteName === item.service}" :key="index">
                    <a v-if="item.link" href="javascript:" class="snb-link" @click="goAws(item.service)">{{ item.name }} <i class="icon-link"></i></a>
                    <router-link v-else :to="{name: item.service}" class="snb-link">{{ item.name }}</router-link>
                </li>
            </ol>
        </nav>
    </aside>
</template>

<script>
import {useRoute} from 'vue-router';
import {computed} from 'vue';
import {goAws} from '@/assets/js/modules/aws/module';

export default {
    name: 'SandboxSideBar',
    setup() {
        const route = useRoute();
        const currentRouteName = computed(() => route.name);

        const items = [
            {
                name: '데이터 마트',
                service: 'DataMart',
                link: false,
            },
            {
                name: 'AWS Glue',
                service: 'Glue',
                link: false,
            },
            {
                name: 'Athena',
                service: 'Athena',
                link: false,
            },
            {
                name: 'SageMaker',
                service: 'SageMaker',
                link: true,
            },
            {
                name: 'QuickSight',
                service: 'QuickSight',
                link: false,
            },
        ];

        return {
            currentRouteName,
            items,
            goAws,
        };
    },
};
</script>
